<template>
    <div v-cloak v-if="isOpen" class="fixed z-[9999] w-[100vw] h-[100vh] flex justify-center items-center bg-popup-bg">
      <div class="p-3 max-h-[100vh] overflow-y-auto">
        <div class="relative bg-white p-6 rounded-[2px] shadow-lg w-full max-w-[600px] min-w-[100px] md:min-w-[300px] xl:min-w-[500px] gap-4 flex flex-col">
          <div class="flex flex-col gap-4 text-md">
            <div class="text-[#3f3f46]" v-html="$t('We use cookies...')"></div>
            <div class="flex items-center gap-2 flex-wrap">
              <SfButton 
                class="font-medium rounded-[2px] p-4 h-[32px] btn btn-primary"
                square 
                variant="primary" 
                @click="acceptAll"
              >
                {{ $t('Accept all') }}
              </SfButton>
              <SfButton 
                class="font-medium rounded-[2px] p-4 h-[32px]"  
                square 
                variant="secondary"  
                @click="rejectAll"
              >
              {{ $t('Reject All') }}
              </SfButton>
            </div>
          </div>
          <div v-cloak>
            <div v-for="category in Object.keys(settings)">
                <p class="text-sm text-[#3f3f46] font-semibold" v-html="$t(settings[category]?.description)"></p>
                <span class="flex flex-wrap items-center gap-2">
                  <FormKit
                    type="checkbox"
                    v-model="preferences[category]"
                    :disabled="!settings[category].optional"
                    :label="$t(settings[category].title) ?? $t(category)"
                  />
                </span>
            </div>
            <div class="flex items-center gap-2 flex-wrap">
              <SfButton
                @click="savePreferences"
                class="font-medium rounded-[2px] p-4 h-[32px] transition-all"
                square 
                variant="secondary"
              >
              {{ $t('Save preferences') }}
            </SfButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import useCookies from './useCookies';
  import {SfButton} from '@storefront-ui/vue'
  const {
    isOpen,
    preferences,
    settings,
    acceptAll,
    rejectAll,
    savePreferences
  } = useCookies();
  </script>
  