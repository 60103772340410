import type {CookieSettings,CookiePopupState, ConsentData} from "./types";
function getCookie(cname: string): string {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '{ "isOpen": true, "preferences": {} }';
}
const settings:CookieSettings = {
  necessary: { 
    default: true, 
    optional: false, 
    title: 'Necessary:',
    description: 'This type of cookie is necessary',
  },
  statistics: { 
    default: false, 
    optional: true, 
    title: 'Statistics:',
    description: 'This type of cookie is for gather statistics',
  },
  marketing: { 
    default: false, 
    optional: true, 
    title: 'Marketing:',
    description: 'This type of cookie is for gather marketing',
  },
}
export default function () {
  const gtm = useGtm();
  const state = reactive<CookiePopupState>(JSON.parse(getCookie('cookie-settings')));
  onMounted(() => {
    Object.keys(settings).forEach(key => {
      if (!state.preferences[key]) {
        state.preferences[key] = settings[key].default 
      }
    })
  })

  function rejectAll() {
    Object.keys(settings).forEach(key => {
      if (settings[key].optional) {
        state.preferences[key] = false;
      }
    });
    savePreferences();
  }

  function acceptAll() {
    Object.keys(settings).forEach(key => {
      if (settings[key].optional) {
        state.preferences[key] = true;
      }
    });
    savePreferences();
  }

  function updateConsent(consent: ConsentData, event: string){
    gtm?.trackEvent({
      event,
      consent,
    });
  }

  function savePreferences() {
    state.isOpen = false;
    const currentDate = new Date();

    // Add 6 months to the current date
    currentDate.setMonth(currentDate.getMonth() + 6);

    // Convert the modified date to a UTC string
    const expires = currentDate.toUTCString();
    let cookie = `cookie-settings=${JSON.stringify(state)}; expires=${expires}; path=/;`;
    document.cookie = cookie;
    const consentData:ConsentData = {
      ad_storage: state.preferences.marketing ? 'granted' : 'denied',
      analytics_storage: state.preferences.statistics ? 'granted' : 'denied',
      ad_user_data: state.preferences.marketing ? 'granted' : 'denied',
      ad_personalization: state.preferences.marketing ? 'granted' : 'denied',
    };
    updateConsent(consentData,'update_consent');
  }



  return {...toRefs(state), settings, rejectAll, acceptAll, savePreferences };
}