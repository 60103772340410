<template>
  <div class="uppercase">
    <div class="uppercase">
      <div class="flex">
        <label for="desktop-currency" class="sr-only">Currency</label>
        <div class="group relative -ml-2 rounded-md border-transparent bg-gray-900 focus-within:ring-2 focus-within:ring-white">
          <select
            v-model="selectedCurrency"
            name="currency"
            class="flex focus items-center rounded border-transparent bg-gray-900 py-0.5 pl-2 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100"
            :title="$t('currency')"
          >
            <option
              :selected="selectedCurrency == currency.iso_code"
              v-for="currency in availableCurrencies"
              :value="currency.iso_code"
              :key="currency.iso_code"
            >
              {{ currency.iso_code }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { data } = useBootstrap();
const route = useRoute();
const vsfCurrencyCookie = useCookie('vsf-currency');

const availableCurrencies = computed(() => {
  return data.value?.currencies?.currencies ?? [];
});

const selectedCurrency = ref(
  route.query.iso_currency as string
  ?? data.value?.currencies?.current_currency?.iso_code 
  ?? 'EUR'
);

watch(() => selectedCurrency.value, () => {
  vsfCurrencyCookie.value = selectedCurrency.value;
  const url = new URL(window.location.href);
  url.searchParams.set('iso_currency', selectedCurrency.value);
  window.history.replaceState(null, '', url);
  location.reload();
});
</script>
