<script lang="ts" setup>
import { LoaderCircle, Search } from 'lucide-vue-next';
import { onClickOutside } from '@vueuse/core'
import VProductPrice from './VProductPrice.vue';

const router = useRouter();
const localePath = useLocalePath();
const { query: searchTerm, data: results, loading, componentSearch} = useSearch();

const showResults = ref(false)
const selectedItemRef = ref<any>(null)
const selectedIndex = ref(-1)
const panel = ref<any>(null);
const wrapper = ref<any>(null);

// Keyboard navigation
const navigateResults = (direction: string) => {
  if (!results.value?.products) return

  if (direction === 'up') {
    selectedIndex.value = Math.max(-1, selectedIndex.value - 1);
  } else {
    selectedIndex.value = Math.min(results.value.products.length - 1, selectedIndex.value + 1);
  }
}

// Click outside handler to close dropdown
const closeDropdown = () => {
  showResults.value = false
  if (document.activeElement) {
    document.activeElement?.blur()
  }
}

const handle = () => {
  if (selectedIndex.value === -1) {
    router.push({ path: localePath('search'), query: { s: searchTerm.value } })
  } else {
    //@ts-ignore
    router.push(new URL(results.value.products[selectedIndex.value].link).pathname)
  }
  closeDropdown();
}

watch(selectedIndex, () => {
  if (!selectedItemRef.value) return
  if (!panel.value) return

  panel.value.scroll({
    top: selectedItemRef.value.offsetTop - panel.value.offsetTop - panel.value.clientHeight / 2 + selectedItemRef.value.clientHeight / 2,
  });
})

onClickOutside(wrapper, () => showResults.value = false)

// Handle input change and initiate component search
const handleInputChange = () => {
  componentSearch.value = true;
};

</script>

<template>
  <div ref="wrapper" class="relative w-full max-w-xl">
     <div class="flex items-center bg-white px-2 rounded border" @click="showResults = true">
      <LoaderCircle v-if="componentSearch && loading" class="h-4 w-4 shrink-0 opacity-50" />
      <Search v-else />
      <input
        ref="input"
        v-model="searchTerm"
        :placeholder="$t('Search')+'...'"
        class="w-full h-9 border-none max-w-xl focus:ring-0"
        @keydown.down.prevent="navigateResults('down')"
        @keydown.up.prevent="navigateResults('up')"
        @keydown.enter="handle"
        @focus="showResults = true"
        @change="selectedIndex = -1"
        @keydown="handleInputChange()"
      />
     </div>
    <div ref="panel" v-show="showResults && searchTerm?.length > 2" class="absolute top-10 z-20 bg-white rounded shadow inset-x-0 border">
      <template v-if="(results?.products?.length ?? 0) <= 0">
        <div class="px-4 py-2 font-semibold">
          No results found.
        </div>
      </template>
      <template v-else>
        <div 
          @click="[router.push(localePath(`/search?s=${searchTerm}&page=1`)),closeDropdown()]"
          :class="[
            'px-4 py-2 cursor-pointer hover:bg-gray-100',
            { 'bg-gray-100': selectedIndex === -1 }
          ]"
        >
          {{ $t('Show all results') }} 
          '<span class="font-semibold">{{ searchTerm }}</span>'
        </div>
        <div  class="max-h-[480px] overflow-visible overflow-y-auto">
         
    
          <div 
            v-for="(result, index) in results?.products"
            :key="result.id" 
            :ref="el => { if (index === selectedIndex) { selectedItemRef = el } }"
            :class="[
              'px-4 py-2 border-b cursor-pointer hover:bg-gray-100',
              { 'bg-gray-100': selectedIndex === index }
            ]"
          >
            <NuxtLink class="flex items-start gap-2" :to="result.link.replace(useRuntimeConfig().public.prestashop_url, '')" @click="closeDropdown">
              <img :src="result.cover.url" class="h-20">
              <div>
                <h3>{{ result.name }}</h3>
                <VProductPrice
                  :prices-data="{
                    specific_prices: result.specific_prices,
                    price: result.price,
                    regular_price: result.regular_price,
                    discount_percentage: result.discount_percentage,
                  }"
                  size="sm"
                  container-styles="py-2"
                />
              </div>
            </NuxtLink>
          </div>
        </div>
      </template>
      
    </div>
  </div>
</template>
