<script lang="ts" setup>
import { useAnnouncementStore } from '~/stores/announcement';
const store = useAnnouncementStore();
</script>

<template>
  <div v-if="store.data?.announcement" class="bg-primary text-primary-foreground border-b border-gray-200 text-gray-800 py-2 text-sm">
    <VContainer class="w-full text-center grid grid-cols-1 gap-1">
      <div class="w-full place-content-center col-span-3 md:col-span-1">
        {{ store.data?.announcement }}
      </div>
    </VContainer>
  </div>
</template>
