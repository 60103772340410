<script lang="ts">
import VModal from '~/components/ui/VModal.vue';
import { useAuth } from '@/stores/auth';

export default defineComponent({
  components: {
    VModal,
  },
  async setup() {
    const router = useRouter();
    const authStore = useAuth();
    const { data: countries, pending, execute } = useCountries();

    const vsfCountryCookie = useCookie('vsf-country');
    const showModal = ref(false);
    const localePath = useLocalePath();

    await execute();

    const currentCountryId = computed(() => (!!vsfCountryCookie.value ? vsfCountryCookie.value : '10'));

    const selectedCountryId = ref(currentCountryId.value);

    const currentCountry = computed(() => {
      return countries.value?.find((country) => country.id_country == currentCountryId.value);
    });

    function openModal() {
      showModal.value = true;
    }

    function closeModal() {
      showModal.value = false;
    }

    function closeModalAndLogin() {
      closeModal();
      router.push(localePath('/login'));
    }

    function applySelectedCountry() {
      vsfCountryCookie.value = selectedCountryId.value;
      const url = new URL(window.location.href);
      url.searchParams.set('id_country', selectedCountryId.value);
      history.replaceState(null, '', url.toString());
      location.reload();
    }

    return {
      isAuthenticated: computed(() => authStore.isAuthenticated),
      countries,
      loading: computed(() => pending.value),
      currentCountry,
      selectedCountryId,
      applySelectedCountry,
      openModal,
      closeModal,
      closeModalAndLogin,
      showModal,
    };
  },
});
</script>

<template>
  <div v-if="!isAuthenticated">
    <button class="flex items-center gap-1" type="button" @click="openModal">
      <svg
        class=""
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="23px"
        viewBox="0 0 395.71 395.71"
        fill="currentColor"
        xml:space="preserve"
      >
        <g>
          <path
            d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
                c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
                C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
                c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"
          />
        </g>
      </svg>
      <span v-if="!loading && currentCountry" class="text-sm font-medium"> {{ currentCountry.name }} </span>
      <span v-else class="text-sm font-medium"> {{ $t('Loading') + '...' }} </span>
    </button>
    <VModal
      v-if="showModal"
      :title="$t('Choose your location')"
      :action-button-text="$t('Apply')"
      @close-modal="closeModal"
      @ok-event="applySelectedCountry"
    >
      <template #body>
        <div>
          <button @click="closeModalAndLogin" class="w-full h-[42px] btn btn-secondary mt-5">
            {{ $t('Sign in to see your addresses') }}
          </button>
          <div class="flex items-center mt-3">
            <span class="border-b border-gray-300 w-full"></span>
          </div>
          <div class="mt-3">
            <div class="text-base mb-3 text-gray-500 w-full text-center">
              {{ $t('Select your country') }}
            </div>
            <select class="form-select" v-model="selectedCountryId">
              <option v-for="(item, i) in countries" :value="item.id_country" :key="`country_${i}`">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </template>
    </VModal>
  </div>
</template>
