<script lang="ts" setup>
import { useSdk } from '~/sdk';
import { useStorage } from '@vueuse/core';

const router = useRouter();
const countryCookie = useCookie('vsf-country');
const cookieId = useCookie('geolocation_id');
const cookieCode = useCookie('geolocation_code');
const answered = useStorage('geolocation_answered', () => false, sessionStorage);
const { data: countries, pending, execute } = useCountries();

const currentCountry = computed(() => {
  return countries.value?.find((country) => country.id_country == countryCookie?.value);
});

const { data } = useAsyncData(async () => {
  if (!cookieCode.value) {
    return null;
  }

  return await useSdk().commerce.getCountry({ code: cookieCode.value });
});

const switchCountry = () => {
  answered.value = true;
  countryCookie.value = cookieId.value;
  router.replace({ query: { id_country: cookieId.value } }).then(() => location.reload());
};
</script>

<template>
  <div
    class="bg-gray-100 sticky top-0 z-[999] border-b shadow-sm text-gray-900 w-full"
    v-if="!answered && data && cookieId != countryCookie"
  >
    <div class="p-4 pr-10 md:flex items-center gap-4 w-full max-w-4xl mx-auto justify-center">
      <p class="text-sm max-w-2xl mb-2 md:mb-0">
        {{
          $t('You are currently accessing the website from {country}, but browsing content tailored for {current}', {
            country: data.name,
            current: currentCountry?.name,
          })
        }}
      </p>
      <VButton variant="secondary" class="whitespace-nowrap" @click="switchCountry">{{ $t('Switch to {country}', { country: data.name }) }}</VButton>
      <button class="absolute top-0 right-0 md:static text-3xl text-gray-900 h-12 w-12" @click="answered = true">&times;</button>
    </div>
  </div>
</template>
