<template>
  <div class="modal__wrapper fixed inset-0 z-[99] flex justify-center items-center">
    <div @click="closeModal" class="fixed inset-0 w-full h-full"></div>
    <div class="modal__content mx-2 w-full relative bg-white overflow-hidden">
      <div class="modal__content--header px-5 h-10 border-b border-neutral-200 flex justify-between items-center">
        <div class="modal__content--title text-[13px] font-bold text-gray-500">
          {{ title }}
        </div>
        <button
          @click="closeModal"
          class="modal__content--close-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="14px"
            height="14px"
          >
            <path
              fill="#43464E"
              d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"
            />
          </svg>
        </button>
      </div>
      <div class="modal__content--body px-5">
        <div class="modal__content--description text-sm text-gray-500">
          {{ description }}
        </div>
        <slot name="body">
        </slot>
      </div>
      <div class="modal__content--actions px-5 py-6">
        <button @click="okEvent" class="btn btn-primary w-full">
          {{ actionButtonText }}
        </button>
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    title: String,
    description: String,
    actionButtonText: String,
    modelValue: Boolean
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    okEvent() {
      this.closeModal();
      this.$emit('ok-event')
    }
  },
  setup(props) {
  }
})
</script>

<style scoped>
.modal {
  &__wrapper {
    background-color: rgba(0, 0, 0, 0.5);
  }
  &__content {
    border-radius: 5px;
    max-width: 400px;
    &--header {
      background: #f7f7f7;
    }
  }
}
</style>
