<script lang="ts">
import useNotifications from '~/stores/notifications';

export default defineComponent({
  props: ['notification'],
  setup() {
    const { remove } = useNotifications();

    return {
      remove,
    };
  },
});
</script>

<template>
  <div
    class="transition-all pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 z-50"
  >
    <div class="p-4 w-full">
      <div class="flex items-start">
        <div class="flex-shrink-0" v-if="notification.type == 'success'">
          <svg
            class="h-6 w-6 text-green-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div class="flex-shrink-0" v-else-if="notification.type == 'error'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6 text-red-400"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-gray-900">
            {{ notification.message }}
          </p>
        </div>
        <button @click="remove(notification.id)">&times;</button>
      </div>
    </div>
  </div>
</template>
