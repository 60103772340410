import { defineStore } from 'pinia';
import { useSdk } from '~/sdk';

export const useAnnouncementStore = defineStore('announcement', () => {
  const { locale } = useI18n();
  
  const state = useAsyncData(async () => {
    return await useSdk().cms.getContent({
      entry: null,
      globalSet: {
        handle: 'announcement',
        fragment: `
          ... on announcement_GlobalSet {
            announcement
          }
        `,
      },
    });
  });

  watch(locale, () => state.refresh());

  return state;
});
