<template>
  <footer class="bg-gray-950 mt-16" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="mx-auto w-full container px-4 sm:px-6 lg:px-8">
      <!-- <div class="border-b border-gray-300/10 py-8 lg:flex lg:items-center lg:justify-between">
        <div>
          <h3 class="text-sm font-semibold leading-6 text-gray-100">Subscribe to our newsletter</h3>
          <p class="mt-2 text-sm leading-6 text-gray-300">The latest news, articles, and resources, sent to your inbox weekly.</p>
        </div>
        <form class="mt-6 sm:flex sm:max-w-md lg:mt-0">
          <label for="email-address" class="sr-only">{{$t('Email address')}}</label>
          <input type="email" name="email-address" id="email-address" autocomplete="email" required class="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:w-56 sm:text-sm sm:leading-6" placeholder="Enter your email" />
          <div class="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
            <v-button type="submit">Subscribe</v-button>
          </div>
        </form>
      </div> -->
      <div class="py-16 border-gray-300/10">
        <div class="grid grid-cols-4 gap-8 text-white">
          <div v-for="submenu in menu" :key="submenu.title" class="col-span-4 w-full md:col-span-2 lg:col-span-1">
            <h3 class="text-sm font-semibold leading-6 text-gray-100">
              {{ submenu.title }}
            </h3>
            <ul role="list" class="mt-5 space-y-4">
              <li v-for="item in (submenu.children || []).filter((i) => i.callToAction.length > 0)" :key="item.id">
                <NuxtLink
                  v-if="item.callToAction[0].element"
                  :to="'/' + (locale + '/') + item.callToAction[0].element.slug"
                  class="text-sm leading-6 text-gray-200 hover:text-gray-300"
                >
                  {{ item.title }}
                </NuxtLink>
                <a
                  class="text-sm leading-6 text-gray-200 hover:text-gray-300"
                  v-else-if="item.callToAction[0].url"
                  :href="item.callToAction.url"
                >
                  {{ item.title }}
                </a>
                <span class="text-sm leading-6 text-gray-200 hover:text-gray-300" v-else>
                  {{ item.title }}
                </span>
              </li>
            </ul>
          </div>
          <div class="col-span-4 w-full md:col-span-2 lg:col-span-1">
            <h3 class="text-sm font-semibold leading-6 text-gray-100 uppercase">
              {{ $t('Account') }}
            </h3>
            <ul role="list" class="mt-5 space-y-4">
              <li v-for="item in account" :key="item.id">
                <NuxtLink :to="item.slug" class="text-sm leading-6 text-gray-200 hover:text-gray-300">{{ item.title }}</NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="socials" class="flex justify-start pb-8 border-b border-gray-300/10">
        <div class="flex items-center gap-2">
          <a
            v-for="(socialItem, i) in socials"
            :href="socialItem.theurl"
            :key="`social-${i}`"
            class="border border-gray-300/10 rounded-full w-9 h-9 flex items-center justify-center hover:bg-gray-300/10"
            target="_blank"
          >
            <NuxtImg 
              :title="socialItem.alias" 
              class="w-5 h-5" 
              :src="getCmsImage(socialItem.icon)"
              format="webp"
              :alt="socialItem.alias"
              loading="lazy" 
            />
          </a>
        </div>
      </div>
      <div class="border-t text-xs border-gray-900/10 py-8 md:flex md:items-center md:justify-between">
        <p class="mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0">
          {{ copyright }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { getCmsImage } from '@/composables/useCms';

const localePath = useLocalePath();
const { t, locale } = useI18n();
const { socials, menu, copyright } = useFooter();

const account = [
  {
    title: t('My account'),
    slug: localePath('/my-account'),
  },
  {
    title: t('Order history'),
    slug: localePath('/my-account/orders'),
  },
  {
    title: t('Addresses'),
    slug: localePath('/my-account/addresses'),
  },
  {
    title: t('Login'),
    slug: localePath('/login'),
  },
  {
    title: t('Register'),
    slug: localePath('/register'),
  },
];
</script>
